var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { giveawaySectionStyles, } from 'root/src/client/web/giveaway/features/GiveawaySection/styles/giveawayStyles';
import { useCreatorActiveGiveaway, useGiveawayTerms, } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import { useCreatorDataApi } from 'root/src/client/logic/api/hooks/useCreatorDataApi';
import { useGiveawayApi } from 'root/src/client/web/giveaway/hooks/useGiveawayApi';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { useGiveawaySection, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { useBetween } from 'use-between';
import { useGiveawayTermsCheckbox, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawayTermsCheckbox';
export const useEnterGiveawayButton = () => {
    const classes = giveawaySectionStyles();
    const { onGiveawayTermsAccept } = useGiveawaySection();
    const giveaway = useCreatorActiveGiveaway();
    const creatorDataApi = useCreatorDataApi();
    const giveawayApi = useGiveawayApi();
    const giveawayTerms = useGiveawayTerms();
    const { isGiveawayTermsCheckboxSelected } = useBetween(useGiveawayTermsCheckbox);
    const [isLoading, setIsLoading] = useState(false);
    const enterGiveaway = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!giveaway || giveaway.isEntered)
            return;
        const { error } = yield giveawayApi.enterGiveaway({
            giveawayId: giveaway.id,
        });
        if (error) {
            throw error;
        }
        yield creatorDataApi.fetchGiveaways();
    });
    const onEnterGiveaway = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isGiveawayTermsCheckboxSelected && !giveawayTerms) {
            return;
        }
        yield onGiveawayTermsAccept();
        setIsLoading(true);
        try {
            yield enterGiveaway();
        }
        catch (err) {
            console.error(err);
            const message = err instanceof Error ? err.message : 'Failed to enter giveaway';
            toast.error(message, {
                style: {
                    backgroundColor: colorsNew.pink,
                    color: colorsNew.white,
                },
            });
        }
        setIsLoading(false);
    });
    return {
        onEnterGiveaway,
        isLoading,
        classes,
        isSteamEntered: Boolean(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isSteamEntered),
    };
};
