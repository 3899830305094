import React, { memo, useEffect } from 'react'
import includes from 'ramda/src/includes'
import filter from 'ramda/src/filter'
import pluck from 'ramda/src/pluck'
import withStyles from '@material-ui/core/styles/withStyles'
import ProgressChart from 'root/src/client/web/base/ProgressChart'
import WidgetTask from 'root/src/client/web/record/WidgetTask'
import {
	EDIT_PROFILE_ROUTE_ID, MANAGE_PAYOUT_ROUTE_ID,
	CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID, CREATE_PROJECT_ROUTE_ID,
	BRAND_SETTINGS_ROUTE_ID, BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { userGroups } from 'root/src/shared/constants/auth'
import Link from 'root/src/client/web/base/Link'
import { ltGtMediaQuery, smMediaQuery, ltSmMediaQuery, colorsNew } from 'root/src/client/web/commonStyles'
import progressCalculator from 'root/src/client/web/record/util/progressCalculator'
import { GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

const checkFirstDareStep = (steps) => {
	const remainingSteps = filter(({ completed }) => !completed, steps)
	const isFirstDareCompleted = !includes('Deliver 1st dare', pluck('title', remainingSteps))
	if (!isFirstDareCompleted && remainingSteps.length === 1) return true
	return false
}

const style = {
	root: {
		backgroundColor: colorsNew.dark2,
		padding: [[20, 0]],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		flexWrap: 'wrap',
		[smMediaQuery]: {
			padding: [[20, 13]],
		},
		[ltSmMediaQuery]: {
			padding: 20,
		},
	},
	oval: {
		border: '4px solid #2A3440',
		borderRadius: 300,
		width: 60,
		height: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 20,
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		[smMediaQuery]: {
			width: '33%',
			flexDirection: 'column',
		},
		[ltSmMediaQuery]: {
			paddingBottom: 20,
		},
	},
	taskWrapper: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: 17,
		padding: 20,
		width: 'fit-content',
		maxWidth: 1300,
		justifyContent: 'end',
		'@media (max-width: 1518px)': {
			width: '100%',
			justifyContent: 'center',
			gap: 13,
		},
		[ltGtMediaQuery]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
			gridAutoFlow: 'row',
			gap: 15,
		},
		[smMediaQuery]: {
			width: '60%',
			padding: 0,
		},
		'@media (max-width: 460px)': {
			width: 'auto',
			padding: 0,
			display: 'initial',
		},
	},
	task: {
		'@media (max-width: 900px)': {
			width: '45%',
			marginBottom: 10,
		},
	},
	title: {
		marginRight: 20,
		[smMediaQuery]: {
			margin: 10,
			textAlign: 'center',
		},
	},
	link: {
		[ltGtMediaQuery]: {
			paddingRight: '3%',
			paddingBottom: '3%',
		},
	},
	modalTask: {
		cursor: 'pointer',
	},
}

const stepsDesc = {
	twitch: ({
		profile,
		extensions,
		obsExtensionAdded,
		gamePlatforms,
		payoutEmail,
		hasCompletedDropProject,
		hasCompletedProject,
	}) => [
		{ title: 'Connect With Twitch', completed: true, routeId: '' },
		{ title: 'Customize your profile', completed: profile, routeId: EDIT_PROFILE_ROUTE_ID },
		{ title: 'Add OBS Display', completed: obsExtensionAdded, routeId: CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID },
		{ title: 'Do a Drop', completed: hasCompletedDropProject, routeId: routeUrls.drops() },
		{ title: 'Add extensions', completed: extensions, href: 'https://dashboard.twitch.tv/extensions/search?q=dare%20drop' },
		{ title: 'Add payout email', completed: payoutEmail, routeId: MANAGE_PAYOUT_ROUTE_ID },
		{ title: 'Deliver 1st dare', completed: hasCompletedProject, routeId: CREATE_PROJECT_ROUTE_ID },
		{ title: 'Set Game Platforms', completed: gamePlatforms, modalId: GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID },
	],
	youtube: ({
		profile,
		payoutEmail,
		hasCompletedDropProject,
		hasCompletedProject,
		gamePlatforms,
	}) => [
		{ title: 'Connect With Youtube', completed: true, routeId: '' },
		{ title: 'Customize your profile', completed: profile, routeId: EDIT_PROFILE_ROUTE_ID },
		{ title: 'Do a Drop', completed: hasCompletedDropProject, routeId: routeUrls.drops() },
		{ title: 'Add payout email', completed: payoutEmail, routeId: MANAGE_PAYOUT_ROUTE_ID },
		{ title: 'Deliver 1st dare', completed: hasCompletedProject, routeId: CREATE_PROJECT_ROUTE_ID },
		{ title: 'Set Game Platforms', completed: gamePlatforms, modalId: GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID },
	],
	brand: ({ hasBrandDetails, hasKeysAndProducts, hasDropEvent }) => [
		{ title: 'Add Publisher', completed: hasBrandDetails, routeId: BRAND_SETTINGS_ROUTE_ID },
		{ title: 'Add Keys or Product', completed: hasKeysAndProducts, routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID },
		{ title: 'Add a Campaign', completed: hasDropEvent, routeId: routeUrls.addCampaign() },
	],
}

const HelperWidget = memo(({
	classes, helperWidgetOnSuccess, createIntroProject,
	introProjectCreated, widgetType, userRole, displayModal,
	...props
}) => {
	const stepsFn = stepsDesc[widgetType]
	if (!stepsFn) return null
	const steps = stepsFn(props)
	const progress = progressCalculator(steps)

	useEffect(() => {
		const firstDareRemains = checkFirstDareStep(steps)
		if (firstDareRemains && !introProjectCreated) {
			createIntroProject()
		}

		if (progress >= 100) {
			if (helperWidgetOnSuccess) helperWidgetOnSuccess()
		}
	}, [progress])
	const textMap = {
		[userGroups.brand]: 'Complete these steps to get started with Dare Drop',
		[userGroups.creator]: 'Complete these steps to get the most out of Dare Drop',
	}
	return (
		<div className={classes.root}>
			<div className={classes.titleWrapper}>
				<ProgressChart percentage={progress} />
				<span className={classes.title}>{textMap[userRole]}</span>
			</div>
			<div className={classes.taskWrapper}>
				{steps.map(task => (task.completed ? (
					<WidgetTask
						key={task.title}
						title={task.title}
						completed={task.completed}
					/>
				) : task.routeId || task.href ? (
					<Link
						href={task.href}
						routeId={task.routeId}
						classes={{ link: classes.link }}
						key={task.title}
					>
						<WidgetTask title={task.title} completed={task.completed} />
					</Link>
				) : (
					<div
						key={task.title}
						className={classes.modalTask}
						onClick={() => {
							displayModal(task.modalId)
						}}
					>
						<WidgetTask title={task.title} completed={task.completed} />
					</div>
				)))}
			</div>
		</div>
	)
})

export default withStyles(style, { name: 'MuiHelperWidget' })(HelperWidget)
