import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import Image from 'next/image';
import SteamSignIn from 'root/src/client/web/giveaway/assets/steam-sign-in.png';
import { GiveawayTimer } from 'root/src/client/web/giveaway/features/GiveawaySection/GiveawayTimer';
import { GiveawayStatus } from '@daredrop-com/rewards-api-contract';
import { Cup, CupSize, CupVariant, } from 'root/src/client/web/giveaway/components/Cup';
import { EnterGiveawayButton } from 'root/src/client/web/giveaway/features/GiveawaySection/EnterGiveawayButton';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
import { useAuthModals } from 'root/src/client/web/auth/hooks/useAuthModals';
import { BaseTooltip, BaseTooltipContent } from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { useGiveawayTermsCheckbox } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawayTermsCheckbox';
import { useBetween } from 'use-between';
export const ViewerGiveawaySection = () => {
    const { classes, isGiveawayTermsVisible, displayGiveawayTermsModal, isGiveawayTermsLoading, } = useGiveawaySection();
    const { isGiveawayTermsCheckboxSelected, toggleGiveawayTermsCheckbox, } = useBetween(useGiveawayTermsCheckbox);
    return (<div className={classes.root}>
			<span className={classes.header}>Reward giveaway</span>
			<GiveawaySection />
			{isGiveawayTermsVisible && (<div className="flex flex-initial gap-2 items-center">
					{isGiveawayTermsLoading ? <LoadingCircles width={30} height={30}/> : (<input id="giveawayTerms" type="checkbox" className="h-4 w-4" disabled={isGiveawayTermsLoading} checked={isGiveawayTermsCheckboxSelected} onChange={() => {
                    toggleGiveawayTermsCheckbox();
                }}/>)}
					<div>
						<label htmlFor="giveawayTerms">I agree to the</label>
						{' '}
						<button onClick={displayGiveawayTermsModal} type="button" className="underline text-link">
							Terms and Conditions
						</button>
					</div>
				</div>)}
		</div>);
};
const SignInWithSteam = () => {
    const { classes, isSteamEntered, signIntoSteam, giveaway, isAuthenticated, isGiveawayTermsVisible, } = useGiveawaySection();
    const { isGiveawayTermsCheckboxSelected } = useBetween(useGiveawayTermsCheckbox);
    const { displaySignInModal } = useAuthModals();
    if (!giveaway || isSteamEntered)
        return null;
    if (!isAuthenticated) {
        return <ButtonNew className="font-bold" onClick={displaySignInModal}>Sign In</ButtonNew>;
    }
    return (<div className={classes.steamSignInRoot}>
			<div className="flex items-center gap-2">
				<span className={classes.steamSignInText}>Sign in through STEAM to enter for a chance to win!
				</span>
				<BaseTooltip tooltip={<BaseTooltipContent>We use Steam auth to combat key re-sellers and bots</BaseTooltipContent>}>
					<span className="bg-secondary rounded-full flex w-6 h-6 justify-center items-center">?</span>
				</BaseTooltip>
			</div>
			<button onClick={signIntoSteam} type="button" className={classes.steamSignInButton} disabled={isGiveawayTermsVisible && !isGiveawayTermsCheckboxSelected}>
				<Image src={SteamSignIn.src} width={170} height={50} alt="Sign in with STEAM"/>
			</button>
		</div>);
};
const ViewerGiveawayInactive = () => {
    const { classes } = useGiveawaySection();
    return (<>
			<Cup variant={CupVariant.Silver}/>
			<span className={classes.creatorNoActiveGiveaway}>This creator currently has no active<br /> giveaways</span>
			<SignInWithSteam />
		</>);
};
const ViewerGiveawayActiveEnter = () => {
    const { isActive, isSteamEntered, isEntered } = useGiveawaySection();
    return (<>
			{isActive && <GiveawayTimer />}
			{!isEntered && <EnterGiveawayButton />}
			{!isSteamEntered && <SignInWithSteam />}
		</>);
};
const ViewerGiveawayActive = () => {
    const { classes, isActive } = useGiveawaySection();
    return (<>
			{isActive && <GiveawayTimer />}
			<Cup size={CupSize.MEDIUM} variant={CupVariant.Gold}/>
			<span className={classes.creatorNoActiveGiveaway}>
				Reward giveaway in progress
			</span>
		</>);
};
const GiveawaySchedulerActive = () => {
    return (<div className="flex flex-col items-center justify-center gap-2">
			<span className="font-bold font-mono uppercase text-xl text-center">Giveaway is being prepared</span>
			<LoadingCircles width={60} height={60}/>
		</div>);
};
const GiveawaySection = () => {
    const { giveaway, isEntered, giveawayScheduler } = useGiveawaySection();
    if (!giveaway) {
        if ((giveawayScheduler === null || giveawayScheduler === void 0 ? void 0 : giveawayScheduler.status) === GiveawayStatus.Active) {
            return <GiveawaySchedulerActive />;
        }
        return <ViewerGiveawayInactive />;
    }
    if (giveaway.status === GiveawayStatus.Active) {
        if (isEntered) {
            return <ViewerGiveawayActive />;
        }
        return <ViewerGiveawayActiveEnter />;
    }
    return null;
};
