var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { giveawaySectionStyles } from 'root/src/client/web/giveaway/features/GiveawaySection/styles/giveawayStyles';
import isOwnProfileSelector from 'root/src/client/logic/creatorData/selectors/isOwnProfileSelector';
import displayModal from 'root/src/client/logic/modal/actions/displayModal';
import { giveawayModuleIds } from 'root/src/client/web/giveaway/descriptions/modules/moduleIds';
import { useAppDispatch, useAppSelector, } from 'root/src/client/logic/helpers/useStore';
import { useMemo, useState, } from 'react';
import { useCreatorActiveGiveaway, useCreatorGiveaways, useCreatorGiveawayScheduler, useCreatorRewards, useGiveawayTerms, } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import { useGiveawayApi } from 'root/src/client/web/giveaway/hooks/useGiveawayApi';
import { useCreatorDataApi } from 'root/src/client/logic/api/hooks/useCreatorDataApi';
import { ActionStrategyId, GiveawayStatus } from '@daredrop-com/rewards-api-contract';
import { useIsAuthenticated } from 'root/src/client/v2/common/hooks/useUser';
import { SIGN_IN_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { useBetween } from 'use-between';
import { useGiveawayTermsCheckbox, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawayTermsCheckbox';
export const useGiveawaySection = () => {
    const classes = giveawaySectionStyles();
    const dispatch = useAppDispatch();
    const isCreatorProfile = useAppSelector(isOwnProfileSelector);
    const giveaways = useCreatorGiveaways();
    const giveaway = useCreatorActiveGiveaway();
    const giveawayApi = useGiveawayApi();
    const creatorDataApi = useCreatorDataApi();
    const giveawayRewards = useCreatorRewards();
    const isAuthenticated = useIsAuthenticated();
    const giveawayTerms = useGiveawayTerms();
    const giveawayScheduler = useCreatorGiveawayScheduler();
    const { isGiveawayTermsCheckboxSelected } = useBetween(useGiveawayTermsCheckbox);
    const [isGiveawayTermsLoading, setIsGiveawayTermsLoading] = useState(false);
    const isLoading = typeof giveaways === 'undefined';
    const hasRewards = useMemo(() => {
        return Boolean(giveawayRewards === null || giveawayRewards === void 0 ? void 0 : giveawayRewards.length);
    }, [giveawayRewards]);
    const onCreateGiveaway = () => {
        dispatch(displayModal(giveawayModuleIds.CREATE_GIVEAWAY_MODAL_MODULE_ID));
    };
    const openRedeemVouchersModal = () => {
        dispatch(displayModal(giveawayModuleIds.REDEEM_MODAL_MODULE_ID));
    };
    const onGiveawayTermsAccept = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsGiveawayTermsLoading(true);
        try {
            yield giveawayApi.saveGiveawayTerms();
        }
        catch (err) {
            console.error(err);
        }
        setIsGiveawayTermsLoading(false);
    });
    const signIntoSteam = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!isAuthenticated) {
            dispatch(displayModal(SIGN_IN_MODAL_MODULE_ID));
            return;
        }
        if (!isGiveawayTermsCheckboxSelected && !giveawayTerms) {
            return;
        }
        yield onGiveawayTermsAccept();
        const steamAction = (_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.actions) === null || _a === void 0 ? void 0 : _a.find(action => action.strategyId === ActionStrategyId.EnterGiveawaySteam);
        if (!steamAction || !giveaway)
            return;
        yield giveawayApi.submitSteamEntry({
            actionId: steamAction.id,
            giveawayId: giveaway.id,
        });
    });
    const stats = useMemo(() => {
        var _a;
        return {
            totalEntries: (_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.stats) === null || _a === void 0 ? void 0 : _a.entryCount,
            userEntries: (giveaway === null || giveaway === void 0 ? void 0 : giveaway.isEntered) ? giveaway.actions.reduce((acc, action) => {
                return acc + action.entries.length * action.score;
            }, 0) : 0,
        };
    }, [giveaway]);
    const enterGiveaway = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!giveaway || giveaway.isEntered)
            return;
        const { error } = yield giveawayApi.enterGiveaway({
            giveawayId: giveaway.id,
        });
        if (!error) {
            void creatorDataApi.fetchGiveaways();
        }
    });
    const displayGiveawayTermsModal = () => {
        dispatch(displayModal(giveawayModuleIds.GIVEAWAY_TERMS_CONDITION_MODAL_MODULE_ID));
    };
    const isGiveawayTermsVisible = giveaway && isAuthenticated && !giveawayTerms;
    return {
        classes,
        hasRewards,
        isCreatorProfile,
        giveaways,
        isLoading,
        giveaway,
        stats,
        isActive: Boolean((giveaway === null || giveaway === void 0 ? void 0 : giveaway.status) === GiveawayStatus.Active),
        isEntered: Boolean(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isEntered),
        isSteamEntered: Boolean(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isSteamEntered),
        onCreateGiveaway,
        openRedeemVouchersModal,
        signIntoSteam,
        enterGiveaway,
        isAuthenticated,
        isGiveawayTermsVisible,
        onGiveawayTermsAccept,
        isGiveawayTermsLoading,
        displayGiveawayTermsModal,
        giveawayScheduler,
    };
};
