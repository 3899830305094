import { useAppDispatch } from 'root/src/client/logic/helpers/useStore';
import displayModal from 'root/src/client/logic/modal/thunks/displayModal';
import { authModuleIds } from 'root/src/client/web/auth/descriptions/modules/authModuleIds';
import ButtonNew from 'root/src/client/web/base/ButtonNew';
const useBecomeCreatorButton = () => {
    const dispatch = useAppDispatch();
    const onBecomeCreator = () => {
        dispatch(displayModal(authModuleIds.TRANSFORM_TO_CREATOR));
    };
    return {
        onBecomeCreator,
    };
};
export const BecomeCreatorButton = () => {
    const { onBecomeCreator } = useBecomeCreatorButton();
    return (<ButtonNew onClick={onBecomeCreator}>
			Become a Creator
		</ButtonNew>);
};
