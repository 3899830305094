import { CreatorGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/CreatorGiveawaySection';
import { ViewerGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/ViewerGiveawaySection';
import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { useCreatorDataApi } from 'root/src/client/logic/api/hooks/useCreatorDataApi';
import { useInterval } from 'root/src/client/v2/common/hooks/useInterval';
import { GIVEAWAY_DEFAULT_POOLING_TIME_MS } from 'root/src/shared/giveaways/giveaways.constants';
import { useEffect } from 'react';
import { useIsActiveTab } from 'root/src/client/v2/common/hooks/useIsActiveTab';
import { useIsWindowFocused } from 'root/src/client/v2/common/hooks/useIsWindowFocused';
import { useCreatorData } from 'root/src/client/logic/api/hooks/useCreatorData';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { GiveawaySchedulerConfigStatus, VoucherStatus, } from '@daredrop-com/rewards-api-contract';
import { useGiveawayApi } from 'root/src/client/web/giveaway/hooks/useGiveawayApi';
export const GiveawaySection = () => {
    const { classes, isCreatorProfile, giveaway, isLoading, giveawayScheduler, } = useGiveawaySection();
    const creatorDataApi = useCreatorDataApi();
    const giveawayApi = useGiveawayApi();
    const creatorData = useCreatorData();
    const isBetaTester = Boolean(creatorData === null || creatorData === void 0 ? void 0 : creatorData.isBetaTester);
    const isActiveTab = useIsActiveTab();
    const isWindowFocused = useIsWindowFocused();
    const isSchedulerActive = Boolean((giveawayScheduler === null || giveawayScheduler === void 0 ? void 0 : giveawayScheduler.status) === GiveawaySchedulerConfigStatus.Active);
    const isPollingEnabled = Boolean(giveaway || isSchedulerActive);
    const isWaitingForScheduler = Boolean(!giveaway && isSchedulerActive);
    const pollingTime = isWaitingForScheduler
        ? GIVEAWAY_DEFAULT_POOLING_TIME_MS / 4
        : GIVEAWAY_DEFAULT_POOLING_TIME_MS;
    useEffect(() => {
        if (isBetaTester && isActiveTab && isWindowFocused) {
            void Promise.allSettled([
                creatorDataApi.fetchGiveaways(),
                creatorDataApi.fetchScheduler(),
                creatorDataApi.fetchRewards(),
                giveawayApi.fetchVouchers(VoucherStatus.Available),
            ]);
        }
    }, [isBetaTester, isActiveTab, isWindowFocused]);
    useEffect(() => {
        void creatorDataApi.fetchScheduler();
    }, []);
    useInterval(() => {
        if (isWaitingForScheduler) {
            void creatorDataApi.fetchScheduler();
        }
        void creatorDataApi.fetchGiveaways();
    }, pollingTime, {
        enabled: Boolean(isPollingEnabled),
    });
    if (isLoading) {
        return (<div className={classes.root}>
				<LoadingCircles width={150} height={150}/>
			</div>);
    }
    if (isCreatorProfile) {
        return <CreatorGiveawaySection />;
    }
    return <ViewerGiveawaySection />;
};
