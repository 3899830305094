import ButtonNew from 'root/src/client/web/base/ButtonNew';
import Link from 'root/src/client/web/base/Link';
import { EDIT_PROFILE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import * as CreatorDataStat from 'root/src/client/web/record/CreatorData/components/CreatorDataStat';
import { useCreatorDataInfo } from 'root/src/client/web/record/CreatorData/hooks/useCreatorDataInfo';
import { useCreatorDataActionButtons } from 'root/src/client/web/record/CreatorData/hooks/useCreatorDataButtons';
import { useIsAdmin } from 'root/src/client/v2/auth/hooks/useIsAdmin';
import { BecomeCreatorButton } from 'root/src/client/web/auth/features/BecomeCreatorButton';
const CreatorDataAvatar = ({ image, alt }) => {
    return (<div className="border-2 rounded-full p-1.5 relative w-24 h-24">
			{image ? <img className="rounded-full drop-shadow-avatar w-full h-full" src={image} alt={alt || "Creator's Avatar"}/> : <LoadingCircles />}
		</div>);
};
const CreatorDataNameSkeleton = () => {
    return (<span className="w-24 h-8 rounded-xl bg-gradient-to-bl from-light-4 to-light-5 self-center sm:self-start "/>);
};
const CreatorDataName = ({ name }) => {
    if (!name) {
        return <CreatorDataNameSkeleton />;
    }
    return (<span className="text-3xl font-mono font-bold text-center sm:text-left">{name}</span>);
};
const useCreatorDataStats = () => {
    const isAdmin = useIsAdmin();
    return {
        isAdmin,
    };
};
const CreatorDataStats = ({ followers, ccv }) => {
    const { isAdmin } = useCreatorDataStats();
    const formattedFollowers = new Intl.NumberFormat('en-US').format(followers || 0);
    return (<div className="flex gap-5">
			<CreatorDataStat.Root>
				<CreatorDataStat.Label>
					Followers:
				</CreatorDataStat.Label>
				<CreatorDataStat.Value>
					{typeof followers !== 'undefined' ? formattedFollowers : <div className="w-8 h-8"><LoadingCircles /></div>}
				</CreatorDataStat.Value>
			</CreatorDataStat.Root>
			{isAdmin && (<CreatorDataStat.Root>
					<CreatorDataStat.Label>
						CCV:
					</CreatorDataStat.Label>
					<CreatorDataStat.Value>
						{typeof ccv !== 'undefined' ? ccv : '?'}
					</CreatorDataStat.Value>
				</CreatorDataStat.Root>)}
		</div>);
};
const CreatorDataDescription = ({ profile }) => {
    if (typeof profile === 'undefined') {
        return <LoadingCircles className="h-16 w-16"/>;
    }
    if (typeof profile.description === 'undefined') {
        return null;
    }
    return (<span className="font-medium">{profile.description}</span>);
};
const CreatorDataSocialLinks = ({ links }) => {
    if (!links || !links.length)
        return null;
    return (<ul className="flex flex-initial flex-wrap gap-x-10 gap-y-4 py-2 items-start justify-start">
			{links.map(({ link, name, icon, username, displayName }) => (<li className="flex flex-initial" key={name}>
					<Link className="flex justify-center items-center gap-2" href={link}>
						{icon && <img width="30" height="30" src={icon === null || icon === void 0 ? void 0 : icon.src} alt={name}/>}
						<span className="text-white">{displayName || username}</span>
					</Link>
				</li>))}
		</ul>);
};
const CreatorDataActionButtons = ({ isOwnProfile, isFollowing }) => {
    const { onFollow, isTransformableToCreator } = useCreatorDataActionButtons();
    return (<div className="flex flex-initial flex-col gap-2 sm:items-end">
			{isTransformableToCreator && <BecomeCreatorButton />}
			{!isOwnProfile && (<ButtonNew onClick={onFollow}>
					<span className="uppercase tracking-wider font-normal text-sm">{isFollowing ? 'Followed' : 'Follow creator'}</span>
				</ButtonNew>)}
			{isOwnProfile && (<Link routeId={EDIT_PROFILE_ROUTE_ID}>
					<ButtonNew>
						<span className="uppercase tracking-wider font-normal text-sm">Edit</span>
					</ButtonNew>
				</Link>)}
		</div>);
};
export const CreatorDataInfo = () => {
    const { creatorData, profile, isOwnProfile, socialLinks } = useCreatorDataInfo();
    return (<div className="flex flex-col border-white border p-4 gap-4 justify-between">
			<div className="flex flex-col sm:flex-row flex-initial items-center sm:items-start gap-4">
				<CreatorDataAvatar image={creatorData === null || creatorData === void 0 ? void 0 : creatorData.image} alt={creatorData === null || creatorData === void 0 ? void 0 : creatorData.displayName}/>
				<div className="flex flex-col gap-2">
					<CreatorDataName name={creatorData === null || creatorData === void 0 ? void 0 : creatorData.displayName}/>
					<CreatorDataStats followers={creatorData === null || creatorData === void 0 ? void 0 : creatorData.followerCount} ccv={creatorData === null || creatorData === void 0 ? void 0 : creatorData.ccv}/>
				</div>
				<CreatorDataActionButtons isFollowing={Boolean(creatorData === null || creatorData === void 0 ? void 0 : creatorData.following)} isOwnProfile={Boolean(isOwnProfile)}/>
			</div>
			<div className="flex flex-col p-4 gap-2">
				<CreatorDataDescription profile={profile}/>
				<CreatorDataSocialLinks links={socialLinks}/>
			</div>
		</div>);
};
