import pipe from 'ramda/src/pipe'
import filter from 'ramda/src/filter'
import prop from 'ramda/src/prop'
import reduce from 'ramda/src/reduce'

export default description => pipe(
	filter(prop('completed')),
	reduce(sum => sum + 100 / description.length, 0),
	Math.round,
)(description)
