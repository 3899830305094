import { useGiveawayTimer } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawayTimer';
import { PickingWinner } from 'root/src/client/web/giveaway/features/GiveawaySection/PickingWinner';
export const GiveawayTimer = () => {
    const { classes, formattedISOTimeLeft, isPickingWinner } = useGiveawayTimer();
    if (isPickingWinner) {
        return <PickingWinner />;
    }
    return (<div className={classes.activeGiveawayInfo}>
			<span className={classes.timeRemainingLabel}>Giveaway time remaining</span>
			<span className={classes.timeRemaining}>{formattedISOTimeLeft}</span>
		</div>);
};
