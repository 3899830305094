var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
export const Root = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('flex items-center gap-1 flex-initial', className)} {...props}>
			{children}
		</div>);
};
export const Label = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames('uppercase text-sm text-light-4 font-bold', className)} {...props}>{children}</span>);
};
export const Value = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames('uppercase text-sm font-bold', className)} {...props}>{children}</span>);
};
