import { cva } from 'class-variance-authority';
import classnames from 'classnames';
const glowLineVariants = {
    red: 'shadow-red-glow',
    green: 'shadow-green-glow',
    purple: 'shadow-purple-glow',
};
const glowLineVariant = cva('h-0.5 rounded bg-white', {
    variants: {
        variant: glowLineVariants,
    },
});
export const GlowLine = ({ className, variant }) => {
    return (<div className={classnames(glowLineVariant({ variant }), className)}/>);
};
