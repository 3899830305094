import * as Stat from 'root/src/client/web/giveaway/components/Stat';
import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { useGiveawayEntries } from 'root/src/client/web/giveaway/features/GiveawayStats/hooks/useGiveawayEntries';
import { ActionEntry } from 'root/src/client/web/giveaway/features/GiveawayStats/ActionEntry';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
export const GiveawayStats = () => {
    return (<div className="flex flex-col gap-2">
			<GiveawayEntryStats />
			<GiveawayEntries />
		</div>);
};
const GiveawayEntryStats = () => {
    var _a, _b, _c;
    const { giveaway, stats, isLoading } = useGiveawaySection();
    return (<div className="flex flex-initial gap-2">
			<Stat.Root className="flex w-2/3">
				<Stat.Label>Giveaway entries</Stat.Label>
				<Stat.Value>{isLoading ? <LoadingCircles width={30} height={30}/> : (_b = (_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.stats) === null || _a === void 0 ? void 0 : _a.entryCount) !== null && _b !== void 0 ? _b : 0}</Stat.Value>
			</Stat.Root>
			<Stat.Root className="flex w-1/3">
				<Stat.Label className="text-teal">Your entries</Stat.Label>
				<Stat.Value className="text-teal">{isLoading ? <LoadingCircles width={30} height={30}/> : (_c = stats.userEntries) !== null && _c !== void 0 ? _c : 0}</Stat.Value>
			</Stat.Root>
		</div>);
};
const GiveawayEntries = () => {
    const { isLogged, entriesList, isEntered } = useGiveawayEntries();
    if (!isLogged) {
        return null;
    }
    return (<ul role="list" className="flex flex-col gap-2">
			{entriesList.map(entry => (<li key={entry.label}>
					<ActionEntry entry={entry} disabled={!isEntered}/>
				</li>))}
		</ul>);
};
