import equals from 'ramda/src/equals'
import over from 'ramda/src/over'
import not from 'ramda/src/not'
import test from 'ramda/src/test'
import lensProp from 'ramda/src/lensProp'
import apiRecordRequestError from 'root/src/client/logic/api/actions/apiRecordRequestError'
import apiRecordRequestSuccess from 'root/src/client/logic/api/actions/apiRecordRequestSuccess'
import { idProp, apiStoreLenses } from 'root/src/client/logic/api/lenses'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import followProcessingEnd from 'root/src/client/logic/creatorData/actions/followProcessingEnd'
import followProcessingStart from 'root/src/client/logic/creatorData/actions/followProcessingStart'
import { PayloadSchemaError } from 'root/src/server/errors'
import { AUDIT_FOLLOW_CREATOR } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { emailRe } from 'root/src/shared/util/regexes'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'

const { viewCreatorDataChild } = apiStoreLenses

export default email => async (dispatch, getState) => {
	const state = getState()

	if (email && !test(emailRe, email)) {
		throw new PayloadSchemaError('Email is invalid')
	}
	if (email === '') {
		throw new PayloadSchemaError('Email is required')
	}

	const payload = {
		recordId: currentRouteParamsRecordId(state),
		email,
	}

	// set the favorites button loading
	dispatch(followProcessingStart())

	const recordType = creatorData
	const recordId = idProp(payload)
	const lambdaRes = await invokeApiRequest(AUDIT_FOLLOW_CREATOR, payload, state)
	const { statusCode, statusError, generalError } = lambdaRes
	if (equals(statusCode, 200)) {
		const prevRecord = viewCreatorDataChild(currentRouteParamsRecordId(state), state)
		dispatch(apiRecordRequestSuccess(recordType, over(lensProp('following'), not, prevRecord)))
		dispatch(followProcessingEnd())
	} else if (recordId) { // else creating, don't need record error state
		const error = { ...statusError, ...generalError }
		dispatch(apiRecordRequestError(recordType, recordId, error))
		dispatch(followProcessingEnd())
	}
	return lambdaRes
}
