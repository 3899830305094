import find from 'ramda/src/find'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import completedWidgetSelector from 'root/src/client/logic/creatorData/selectors/completedWidgetSelector'
import isOwnProfileSelector from 'root/src/client/logic/creatorData/selectors/isOwnProfileSelector'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

export default (state) => {
	const isOwnProfile = isOwnProfileSelector(state)
	const completedWidget = completedWidgetSelector(state)
	if (isOwnProfile && completedWidget === false) {
		const recordId = currentRouteParamsRecordId(state)
		const tokens = tokensSelector(state)
		return pipe(
			find(propEq('mnemonicId', recordId)),
			prop('platform'),
		)(tokens)
	}

	return undefined
}
