import React, { memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { smMediaQuery } from 'root/src/client/web/commonStyles'

const xxsMediaQuery = '@media (max-width: 450px)'
const style = {
	percent: {
		width: 64,
		height: '50%',
		marginRight: 20,
		position: 'relative',
		[smMediaQuery]: {
			marginRight: 0,
		},
	},
	progressValue: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		[xxsMediaQuery]: {
			fontSize: 14,
		},
	},
	svg: {
		[xxsMediaQuery]: {
			width: 50,
			height: 50,
		},
	},
}

const ProgressChart = memo(({ percentage, classes }) => (
	<div className={classes.percent}>
		<div className={classes.progressValue}>{percentage}<span>%</span></div>
		<svg viewBox="0 0 36 36" className={classes.svg}>
			<path
				d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
				fill="none"
				stroke="#2A3440"
				strokeWidth="2"
				strokeDasharray="100, 100"
			/>
			<path
				d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
				fill="none"
				stroke="#5C40B6"
				strokeWidth="2"
				strokeDasharray={`${percentage},100`}
			/>
		</svg>
	</div>
))

export default withStyles(style, { name: 'MuiProgressChart' })(ProgressChart)
