import pick from 'ramda/src/pick'
import pipe from 'ramda/src/pipe'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { pathOrMediaBreakpoints } = appStoreLenses

export default pipe(
	pathOrMediaBreakpoints({}),
	pick(['gtLg', 'gtMd', 'gtSm', 'gtXs']),
)
