import * as Entry from 'root/src/client/web/giveaway/components/Entry';
import ButtonNew from 'root/src/client/web/base/ButtonNew';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { useActionEntry } from 'root/src/client/web/giveaway/features/GiveawayStats/hooks/useActionEntry';
import { BaseTooltip, BaseTooltipContent } from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { typographyVariants } from 'root/src/client/web/typography/Typography';
import { useIsAuthenticated } from 'root/src/client/v2/common/hooks/useUser';
import { useCreatorActiveGiveaway } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
const EntryDisabledTooltipContent = () => {
    const isAuthenticated = useIsAuthenticated();
    const giveaway = useCreatorActiveGiveaway();
    if (!isAuthenticated) {
        return (<BaseTooltipContent>
				You cannot submit this entry<br />
				Please log in.
			</BaseTooltipContent>);
    }
    if (!(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isSteamEntered)) {
        return (<BaseTooltipContent>
				You cannot submit this entry<br />
				Please connect your Steam account.
			</BaseTooltipContent>);
    }
    if (!(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isEntered)) {
        return (<BaseTooltipContent>
				You cannot submit this entry<br />
				Please enter to the giveaway.
			</BaseTooltipContent>);
    }
    return null;
};
export const ActionEntry = (props) => {
    var _a, _b;
    const { entry, disabled } = props;
    const { onEntrySubmit, isLoading } = useActionEntry(entry);
    return (<Entry.Root>
			<Entry.Label className="gap-4">
				<Entry.LabelIcon src={((_a = entry.icon) === null || _a === void 0 ? void 0 : _a.src) || ''} alt={entry.label}/>
				<Entry.LabelText>{entry.label}</Entry.LabelText>
			</Entry.Label>
			<Entry.Trigger>
				{entry.isDone ? <Entry.SuccessIcon /> : (<>
						<BaseTooltip tooltip={disabled && <EntryDisabledTooltipContent />}>
							<ButtonNew style={{ background: colorsNew.white, borderRadius: '100px', height: 40, width: 120 }} disabled={disabled} onClick={onEntrySubmit} typography={typographyVariants.providerSignIn}>
								<Entry.ButtonContent>
									<Entry.ButtonIcon src={((_b = entry.icon) === null || _b === void 0 ? void 0 : _b.src) || ''} alt={entry.label}/>
									<Entry.ButtonAction>{entry.actionName}</Entry.ButtonAction>
								</Entry.ButtonContent>
							</ButtonNew>
						</BaseTooltip>
						<Entry.Score>{isLoading ? <LoadingCircles width={20} height={20}/> : `+${entry.score}`}</Entry.Score>
					</>)}
			</Entry.Trigger>
		</Entry.Root>);
};
