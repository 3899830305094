import moment from 'moment-mini';
import { useState } from 'react';
import { useInterval } from 'root/src/client/v2/common/hooks/useInterval';
export function useCountdown(date) {
    const [timeLeft, setTimeLeft] = useState(() => {
        return new Date(date).getTime() - new Date().getTime();
    });
    const interval = useInterval(() => {
        setTimeLeft(getTimeLeft(date));
    }, 1000);
    return {
        timeLeft,
        interval,
    };
}
/**
 * Returns time left in milliseconds
 * @param date
 * @example
 * getTimeLeft('2021-08-01T00:00:00.000Z') // 1627776000000
 * @returns
 */
export function getTimeLeft(date) {
    return new Date(date).getTime() - new Date().getTime();
}
/**
 * Returns ISO time format ex. 04:10:52 (4 hours, 10 minutes, 52 seconds)
 */
export function formatISOTimeLeft(timeLeft) {
    const duration = moment.duration(timeLeft);
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
    const minutes = Math.floor(duration.minutes()).toString().padStart(2, '0');
    const seconds = Math.floor(duration.seconds()).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}
