import pick from 'ramda/src/pick'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

const { viewCreatorDataChild } = apiStoreLenses

export default (state) => {
	const recordId = currentRouteParamsRecordId(state)
	const creatorData = viewCreatorDataChild(recordId, state)
	if (creatorData) {
		return pick(
			['youtubeData', 'twitchData', 'tiktokData', 'facebookData'],
			viewCreatorDataChild(recordId, state),
		)
	}
	return {}
}
