import Slider from 'root/src/client/web/base/Slider';
import identity from 'ramda/src/identity';
import { platformIconMap } from 'root/src/shared/constants/dropKeys';
import * as RewardCard from 'root/src/client/web/giveaway/components/RewardCard';
import { useGiveawayRewards } from 'root/src/client/web/giveaway/features/GiveawayRewards/hooks/useGiveawayRewards';
import classnames from 'classnames';
export const GiveawayRewards = () => {
    const { rewards, sliderBreakpoints, classes } = useGiveawayRewards();
    // means user has no rewards available
    if (!(rewards === null || rewards === void 0 ? void 0 : rewards.length))
        return null;
    return (<div className="flex flex-col gap-5">
			<span className="uppercase text-xs text-light-4 mx-4">Reward giveaway options</span>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Slider containerClassName="gap-8 flex" arrows renderItems={() => identity} renderSliderItem={() => identity} showItems={sliderBreakpoints} infinite arrowsPadding={30} sliderItemsStyle={classes.sliderItems}>
				{rewards.map(reward => (<Reward reward={reward}/>))}
			</Slider>
		</div>);
};
export function Reward({ reward }) {
    const region = 'region' in reward ? `(${reward.region})` : '';
    return (<RewardCard.Root className={classnames('flex-1 border', reward.isClaimed ? 'border-gray-1' : 'border-teal')} key={reward.id}>
			{(reward.isClaimed) && (<RewardCard.ClaimedLabel />)}
			<RewardCard.ImageContainer className={classnames(reward.isClaimed ? 'border-gray-1' : '')}>
				<RewardCard.GameImage src={reward.image} alt={reward.name}/>
			</RewardCard.ImageContainer>
			<RewardCard.GameTitle>{reward.name}</RewardCard.GameTitle>
			<RewardCard.Details>
				<RewardCard.RewardTitle className={classnames(reward.isClaimed ? 'text-gray-1' : '')}>
					{reward.name} code {region}
				</RewardCard.RewardTitle>
				{typeof reward.platform === 'string' && <RewardCard.PlatformIcon icon={platformIconMap[reward.platform]}/>}
				{reward.restrictions && (<RewardCard.RewardRestrictions>
						{reward.restrictions}
					</RewardCard.RewardRestrictions>)}
			</RewardCard.Details>
		</RewardCard.Root>);
}
