import {
	tertiaryColor, lightGrey, ltGtMediaQuery,
	smMediaQuery, ltSmMediaQuery, gtSmMediaQuery, mdMediaQuery, primaryGradient,
	secondaryGradient, ltMdMediaQuery, colorsNew, gtMdMediaQuery,
} from 'root/src/client/web/commonStyles'
import loadingBlockStyle from 'root/src/client/web/base/commonStyle/loadingBlockStyle'
import { creatorTiersEnum } from 'root/src/shared/constants/enums'

const tiersStyles = {
	[creatorTiersEnum.CREATOR]: {
		filter: 'drop-shadow(1px 1px 7px #BBB)',
	},
	[creatorTiersEnum.AFFILIATE]: {
		filter: 'drop-shadow(1px 1px 7px #FFFF33)',
	},
	[creatorTiersEnum.PARTNER]: {
		filter: 'drop-shadow(1px 1px 7px #FF4500)',
	},
	[creatorTiersEnum.ELITE]: {
		filter: 'drop-shadow(1px 1px 7px #F012BE)',
	},
}

export default {
	container: {
		flex: 'initial',
		display: 'flex',
		flexDirection: 'column',
		minHeight: 'initial !important',
	},
	creatorRow: {
		width: '100%',
		gap: 20,
		minHeight: '25vh',
		display: 'flex',
		justifyContent: 'space-between',
		[ltMdMediaQuery]: {
			flexDirection: 'column',
		},
	},
	creatorDataWrapper: {
		position: 'relative',
		padding: '35px 35px 15px 35px',
		display: 'flex',
		justifyContent: 'space-between',
		overflowWrap: 'break-word',
		flexWrap: 'wrap',
		width: '60%',
		boxSizing: 'border-box',
		boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
		border: '1px solid white',
		[ltMdMediaQuery]: {
			position: 'inherit',
			flexDirection: 'column',
			width: '100%',
			marginTop: 0,
			padding: 25,
			flexWrap: 'nowrap',
		},
	},
	topContributorsWrapper: {
		width: '36%',
		marginTop: 46,
		boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
		position: 'relative',
		background: colorsNew.dark2,
		[ltMdMediaQuery]: {
			width: '100%',
			marginTop: 0,
			boxShadow: 'none',
		},
		[ltSmMediaQuery]: {
			maxWidth: 350,
			margin: [[0, 'auto']],
			paddingBottom: 30,
		},
	},
	topContributorsContainer: {
		padding: '0px 25px 35px 25px',
		[ltGtMediaQuery]: {
			padding: '0px 0px 35px 0px',
		},
	},
	creatorInfoWrapper: {
		width: '85%',
		[ltMdMediaQuery]: {
			width: '100%',
			textAlign: 'justify',
			marginTop: 20,
		},
	},
	socialLabelWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 10,
		[ltMdMediaQuery]: {
			paddingBottom: 10,
			borderBottom: `1px solid ${lightGrey}`,
			cursor: 'pointer',
		},
	},
	socialLabel: {
		flex: 1,
		color: tertiaryColor,
		userSelect: 'none',
		[ltMdMediaQuery]: {
			color: 'white',
		},
	},
	contributorsLabelWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[ltMdMediaQuery]: {
			borderBottom: `1px solid ${lightGrey}`,
			cursor: 'pointer',
		},
	},
	contributorsLabel: {
		marginTop: 0,
		flex: 1,
		userSelect: 'none',
		[ltMdMediaQuery]: {
			color: 'inherit',
			paddingBottom: 10,
			margin: 0,
		},
		[smMediaQuery]: {
			padding: 0,
			paddingBottom: 10,
		},
		padding: '15px 0',
		margin: [[0, 'auto']],
	},
	creatorLinksWrapper: {
		position: 'absolute',
		top: 35,
		right: 35,
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		maxWidth: 250,
		[mdMediaQuery]: {
			maxWidth: 190,
		},
		[ltMdMediaQuery]: {
			margin: [[0, 'auto']],
			maxWidth: 350,
			position: 'inherit',
			top: 0,
			right: 0,
			marginBottom: 35,
		},
	},
	btnWrapper: {
		width: '100% !important',
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center',
		border: '2px solid white',
		width: '10%',
		[ltMdMediaQuery]: {
			alignSelf: 'center',
			margin: '0 0 20px 0',
			width: '25%',
		},
	},
	noAuthedImage: {
		margin: '0 !important',
	},
	socialLinksWrapper: {
		margin: [0, 'auto'],
		[ltGtMediaQuery]: {
			width: '100%',
			marginBottom: 30,
		},
		[ltSmMediaQuery]: {
			maxWidth: 350,
			margin: [[0, 'auto']],
		},
		[gtMdMediaQuery]: {
			paddingLeft: '15%',
		},
	},
	titleWrapper: {
		textAlign: 'left',
		[ltMdMediaQuery]: {
			textAlign: 'center',
			marginBottom: 30,
		},
	},
	image: {
		width: '100%',
		border: '2px solid white',
		borderRadius: 300,
		filter: 'drop-shadow(0px 0px 10px #00FFED) drop-shadow(0px 0px 8px #00FFED)',
		height: 'auto',
	},
	description: {
		lineHeight: '24px',
		marginTop: 20,
		textAlign: 'justify',
	},
	linkStyle: {
		textDecoration: 'none',
		color: 'black',
	},
	creatorNameLink: {
		marginLeft: 5,
	},
	noDisplay: {
		display: 'none !important',
	},
	backgroundImageRoot: {
		position: 'relative',
		overflow: 'hidden',
	},
	bgImage: {
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		display: 'block',
		[ltMdMediaQuery]: {
			height: 180,
		},
	},
	arrowUp: {
		transform: 'rotate(180deg) !important',
	},
	arrowTransition: {
		transitionDuration: '0.5s',
	},
	contributorsContent: {
		[ltMdMediaQuery]: {
			paddingTop: 20,
		},
	},
	noContributors: {
		color: tertiaryColor,
		marginTop: 50,
	},
	linksContainer: {
		minHeight: 42,
		display: 'flex',
		flexWrap: 'wrap',
	},
	socialLinkWrapper: {
		display: 'flex',
		padding: '0 50px 0 0',
		alignItems: 'center',
		justifyContent: 'left',
	},
	socialLink: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 20,

	},
	socialIcon: {
		width: 20,
	},
	height: {
		height: 48,
	},
	creatorSocialLinkAndTopContributorWrapper: {
		flexDirection: 'column',
		[smMediaQuery]: {
			display: 'flex',
			padding: [[0, 0, 5, 0]],
			justifyContent: 'space-between',
		},
		[ltSmMediaQuery]: {
			paddingLeft: 'unset',
		},
		[gtSmMediaQuery]: {
			width: '100%',
		},
	},
	desktopWidthWrapper: {
		display: 'none',
		[gtSmMediaQuery]: {
			display: 'unset',
		},
	},
	displayNone: {
		[gtSmMediaQuery]: {
			display: 'none',
		},
	},
	buttonContainer: {
		position: 'absolute',
		right: 4,
		bottom: 4,
	},
	button: {
		border: 'none',
		borderRadius: 4,
		backgroundImage: primaryGradient,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundImage: secondaryGradient,
		},
		padding: [[6, 8, 6, 8]],
	},
	icon: {
		margin: [[0, 0, 0, 6]],
	},
	tier: {
		fontWeight: 700,
		textShadow: '1px 2px 2px #000',
	},
	tierName: {
		marginTop: 20,
		fontSize: 12,
	},
	adminData: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	trashButton: {
		position: 'absolute',
		right: 4,
		bottom: 50,
		display: 'flex',
		justifyContent: 'center',
	},
	...loadingBlockStyle,
	...tiersStyles,
}
