import { useCallback, useEffect, useRef } from 'react';
export function useInterval(callback, delay, config) {
    const savedCallback = useRef();
    const intervalId = useRef();
    const clear = useCallback(() => {
        clearInterval(intervalId.current);
    }, [intervalId]);
    useEffect(() => {
        savedCallback.current = callback;
    }, [savedCallback, callback]);
    useEffect(() => {
        var _a;
        function tick() {
            var _a;
            (_a = savedCallback.current) === null || _a === void 0 ? void 0 : _a.call(savedCallback);
        }
        if ((_a = config === null || config === void 0 ? void 0 : config.enabled) !== null && _a !== void 0 ? _a : true) {
            intervalId.current = setInterval(tick, delay);
        }
        return () => {
            clear();
        };
    }, [delay, config]);
    return {
        clear,
    };
}
