/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { faArrows } from '@fortawesome/pro-light-svg-icons/faArrows'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import classNames from 'classnames'
import { useWindowSize } from 'root/src/client/v2/common/hooks/useWindowSize'
import { CROP_IMAGE_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'
import Icon from 'root/src/client/web/base/Icon'

export default ({ backgroundImage, classes, displayModal, isOwnProfile, deleteProfileBackground }) => {
	if (!backgroundImage) return null
	const [width] = useWindowSize()
	return (
		<div className={classes.backgroundImageRoot}>
			<div
				className={classNames(classes.bgImage)}
				css={{
					height: width / 4.286,
					backgroundImage: `url(${addHttpsProtocol(backgroundImage.url)})`,
					transform: `scale(${backgroundImage.zoom})`,
					transformOrigin: `${backgroundImage.x}% ${backgroundImage.y}%`,
					backgroundPosition: `${backgroundImage.x}% ${backgroundImage.y}%`,
				}}
			/>
			{isOwnProfile && (
				<div className={classes.buttonContainer}>
					<button
						type="button"
						className={classes.button}
						onClick={backgroundImage ? () => displayModal(
							CROP_IMAGE_MODAL_MODULE_ID,
							{
								saveFn: async ({ setLoading, setImagePosition, coordinates }) => {
									setLoading(true)
									await setImagePosition({ ...backgroundImage, ...coordinates })
									setLoading(false)
									displayModal(undefined)
								},
								cropParameters: { aspect: 4.286 },
							},
						) : () => {}}
					>Crop Banner
						<Icon className={classes.icon} icon={faArrows} size={20} />
					</button>
				</div>
			)}
			{isOwnProfile && (
				<div className={classes.trashButton}>
					<button
						type="button"
						className={classes.button}
						onClick={deleteProfileBackground}
					>
						Delete
						<Icon className={classes.icon} icon={faTrash} size={20} />
					</button>
				</div>
			)}
		</div>
	)
}
