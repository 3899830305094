var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useGiveawayApi } from 'root/src/client/web/giveaway/hooks/useGiveawayApi';
import { useAppDispatch } from 'root/src/client/logic/helpers/useStore';
import { useCreatorDataApi } from 'root/src/client/logic/api/hooks/useCreatorDataApi';
import { useGiveawaySection, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { useState } from 'react';
import { EntryFailureCode } from 'root/src/shared/giveaways/giveaways.constants';
import displayModal from 'root/src/client/logic/modal/thunks/displayModal';
import { giveawayModuleIds } from 'root/src/client/web/giveaway/descriptions/modules/moduleIds';
import { authModuleIds } from 'root/src/client/web/auth/descriptions/modules/authModuleIds';
import { SUCCESS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { ScopeSet } from 'root/src/shared/@types/auth';
export const useActionEntry = (entry) => {
    const giveawayApi = useGiveawayApi();
    const dispatch = useAppDispatch();
    const creatorDataApi = useCreatorDataApi();
    const { giveaway } = useGiveawaySection();
    const [isLoading, setIsLoading] = useState(false);
    const entrySubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!giveaway || !giveaway.isEntered)
            return;
        const { error } = yield giveawayApi.createEntry({
            actionId: entry.id,
            giveawayId: giveaway.id,
            strategyId: entry.strategyId,
        });
        if (error) {
            console.error(error);
            if (error.code === EntryFailureCode.NOT_FOLLOWING) {
                const { props } = error;
                dispatch(displayModal(giveawayModuleIds.ENTRY_FAILURE_MODAL_MODULE_ID, {
                    actionId: entry.id,
                    giveawayId: giveaway.id,
                    strategyId: entry.strategyId,
                    url: props.url,
                    displayName: props.displayName,
                }));
                return;
            }
            if (error.code === EntryFailureCode.NOT_CONNECTED_ACCOUNT) {
                const { props } = error;
                dispatch(displayModal(authModuleIds.SIGN_IN_WITH_PROVIDER_MODAL_MODULE, {
                    provider: props.provider,
                    label: props.label,
                    header: 'Connect Channel',
                    isLinking: true,
                    scopeSet: ScopeSet.viewer,
                }));
                return;
            }
            if (error.code === EntryFailureCode.EXTERNAL_ERROR) {
                const { props } = error;
                dispatch(displayModal(authModuleIds.SIGN_IN_WITH_PROVIDER_MODAL_MODULE, {
                    provider: props.provider,
                    scopeSet: ScopeSet.viewer,
                }));
                return;
            }
            dispatch(displayModal(SUCCESS_MODAL_MODULE_ID, {
                header: 'Error',
                errorText: 'Failed to enter giveaway',
                infoText: (error === null || error === void 0 ? void 0 : error.message) || 'Please contact with support',
            }));
            return;
        }
        // refetch giveaways
        yield creatorDataApi.fetchGiveaways();
        // close modal
        dispatch(displayModal(undefined));
    });
    const onEntrySubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield entrySubmit();
        }
        catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    });
    return {
        onEntrySubmit,
        isLoading,
    };
};
