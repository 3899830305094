import reduxConnector from 'root/src/shared/util/reduxConnector'
import imageSelector from 'root/src/client/logic/creatorData/selectors/imageSelector'
import oauthedPlatformsSelector from 'root/src/client/logic/creatorData/selectors/oauthedPlatformsSelector'
import displayNameSelector from 'root/src/client/logic/creatorData/selectors/displayNameSelector'
import loadingBlockVisibleSelector from 'root/src/client/logic/creatorData/selectors/loadingBlockVisibleSelector'
import followingSelector from 'root/src/client/logic/creatorData/selectors/followingSelector'
import followProcessingSelector from 'root/src/client/logic/creatorData/selectors/followProcessingSelector'
import auditFollowCreator from 'root/src/client/logic/creatorData/thunks/auditFollowCreator'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import isAuthenticated from 'root/src/client/logic/auth/selectors/isAuthenticated'
import isOwnProfileSelector from 'root/src/client/logic/creatorData/selectors/isOwnProfileSelector'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import topContributorselector from 'root/src/client/logic/creatorData/selectors/topContributorsSelector'
import descriptionSelector from 'root/src/client/logic/creatorData/selectors/descriptionSelector'
import socialLinksSelector from 'root/src/client/logic/creatorData/selectors/socialLinksSelector'
import backgroundImageSelector from 'root/src/client/logic/creatorData/selectors/backgroundImageSelector'
import profileHasOauthedSelector from 'root/src/client/logic/creatorData/selectors/profileHasOauthedSelector'
import hasPayoutEmailSelector from 'root/src/client/logic/creatorData/selectors/hasPayoutEmailSelector'
import helperWidgetOnSuccess from 'root/src/client/logic/creatorData/thunks/helperWidgetOnSuccess'
import obsExtensionAddedSelector from 'root/src/client/logic/creatorData/selectors/obsExtensionAddedSelector'
import hasExtensionsSelector from 'root/src/client/logic/creatorData/selectors/hasExtensionsSelector'
import creatorDataSocialSelector from 'root/src/client/logic/creatorData/selectors/creatorDataSocialSelector'
import platformSelector from 'root/src/client/logic/creatorData/selectors/platformSelector'
import userIsOauthedSelector from 'root/src/client/logic/creatorData/selectors/userIsOauthedSelector'
import widgetTypeSelector from 'root/src/client/logic/creatorData/selectors/widgetTypeSelector'
import mnemonicIdSelector from 'root/src/client/logic/creatorData/selectors/mnemonicIdSelector'
import hasCompletedProjectSelector from 'root/src/client/logic/creatorData/selectors/hasCompletedProjectSelector'
import hasCompletedDropProjectSelector from 'root/src/client/logic/creatorData/selectors/hasCompletedDropProjectSelector'
import introProjectCreatedSelector from 'root/src/client/logic/creatorData/selectors/introProjectCreatedSelector'
import gamePlatformsSelector from 'root/src/client/logic/app/selectors/gamePlatformsSelector'
import ccvSelector from 'root/src/client/logic/creatorData/selectors/ccvSelector'
import followerCountSelector from 'root/src/client/logic/creatorData/selectors/followerCountSelector'

import oauthAuthenticate from 'root/src/client/logic/auth/thunks/oauthAuthenticate'
import isAdminSelector from 'root/src/client/logic/auth/selectors/isAdminSelector'
import createIntroProject from 'root/src/client/logic/creatorData/thunks/createIntroProject'
import userRoleSelector from 'root/src/client/logic/auth/selectors/userRoleSelector'
import breakpointsSelector from 'root/src/client/logic/app/selectors/breakpointsSelector'
import tierSelector from 'root/src/client/logic/creatorData/selectors/tierSelector'
import { betaTesterSelector } from 'root/src/client/logic/api/hooks/useCreatorData'
import { deleteProfileBackground } from 'root/src/client/logic/profile/thunks/deleteProfileBackground'

export default reduxConnector(
	[
		['mnemonicId', mnemonicIdSelector],
		['displayName', displayNameSelector],
		['image', imageSelector],
		['following', followingSelector],
		['loadingBlockVisible', loadingBlockVisibleSelector],
		['followProcessing', followProcessingSelector],
		['isAuthenticated', isAuthenticated],
		['oauthedPlatforms', oauthedPlatformsSelector],
		['isOwnProfile', isOwnProfileSelector],
		['isMobileRes', isMobileResSelector],
		['topContributors', topContributorselector],
		['description', descriptionSelector],
		['socialLinks', socialLinksSelector],
		['backgroundImage', backgroundImageSelector],
		['profileHasOauthed', profileHasOauthedSelector],
		['hasPayoutEmail', hasPayoutEmailSelector],
		['hasExtensions', hasExtensionsSelector],
		['hasCompletedProject', hasCompletedProjectSelector],
		['hasCompletedDropProject', hasCompletedDropProjectSelector],
		['introProjectCreated', introProjectCreatedSelector],
		['creatorDataSocial', creatorDataSocialSelector],
		['platform', platformSelector],
		['obsExtensionAdded', obsExtensionAddedSelector],
		['userIsOauthed', userIsOauthedSelector],
		['widgetType', widgetTypeSelector],
		['isAdmin', isAdminSelector],
		['userRole', userRoleSelector],
		['breakpoints', breakpointsSelector],
		['tier', tierSelector],
		['gamePlatforms', gamePlatformsSelector],
		['ccv', ccvSelector],
		['followerCount', followerCountSelector],
		['isBetaTester', betaTesterSelector],
	],
	[
		['auditFollowCreator', auditFollowCreator],
		['displayModal', displayModal],
		['helperWidgetOnSuccess', helperWidgetOnSuccess],
		['createIntroProject', createIntroProject],
		['oauthAuthenticate', oauthAuthenticate],
		['deleteProfileBackground', deleteProfileBackground],
	],
)
