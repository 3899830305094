import { useCreatorResolvedGiveaway, useUserVouchers, } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import { useUserAuthentication } from 'root/src/client/v2/common/hooks/useUser';
import { VoucherStatus } from '@daredrop-com/rewards-api-contract';
import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import displayNameSelector from 'root/src/client/logic/creatorData/selectors/displayNameSelector';
export const useRewardWinnerDetailsSection = () => {
    var _a, _b, _c;
    const userVouchers = useUserVouchers();
    const userAuthentication = useUserAuthentication();
    const resolvedGiveaway = useCreatorResolvedGiveaway();
    const creatorName = useAppSelector(displayNameSelector);
    const winner = (_b = (_a = resolvedGiveaway === null || resolvedGiveaway === void 0 ? void 0 : resolvedGiveaway.vouchers) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.owner;
    const awardClaimed = (_c = resolvedGiveaway === null || resolvedGiveaway === void 0 ? void 0 : resolvedGiveaway.vouchers) === null || _c === void 0 ? void 0 : _c.find(voucher => voucher.status === VoucherStatus.Used);
    return {
        isUserWinner: (winner === null || winner === void 0 ? void 0 : winner.id) === (userAuthentication === null || userAuthentication === void 0 ? void 0 : userAuthentication.userId),
        awardClaimed,
        winnerName: winner === null || winner === void 0 ? void 0 : winner.name,
        userVouchers,
        giveaway: resolvedGiveaway,
        creatorName,
    };
};
