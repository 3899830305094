import { useEffect, useState } from 'react';
export function useIsWindowFocused() {
    const [isWindowFocused, setIsWindowFocused] = useState(true);
    useEffect(() => {
        const onFocus = () => {
            setIsWindowFocused(true);
        };
        const onBlur = () => {
            setIsWindowFocused(false);
        };
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onFocus);
        };
    }, []);
    return isWindowFocused;
}
