import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import twitchIcon from 'root/src/client/public/icons/ic-twitch-colorized.svg';
import youtubeIcon from 'root/src/client/public/icons/ic-youtube-colorized.svg';
import { useUserAuthentication } from 'root/src/client/v2/common/hooks/useUser';
import { ActionStrategyId } from '@daredrop-com/rewards-api-contract';
import { useCreatorData } from 'root/src/client/logic/api/hooks/useCreatorData';
// actions hidden from list
const hiddenEntryStrategyIds = [
    // shown in giveaway section
    ActionStrategyId.EnterGiveaway,
    // shown in giveaway section
    ActionStrategyId.EnterGiveawaySteam,
];
const entryIcon = {
    [ActionStrategyId.EnterGiveawayTwitch]: twitchIcon,
    [ActionStrategyId.EnterGiveawayYoutube]: youtubeIcon,
};
const entryLabel = {
    [ActionStrategyId.EnterGiveawayTwitch]: params => `Follow ${params.displayName} on Twitch`,
    [ActionStrategyId.EnterGiveawayYoutube]: params => `Subscribe ${params.displayName} on YouTube`,
};
const entryAction = {
    [ActionStrategyId.EnterGiveawayTwitch]: 'Follow',
    [ActionStrategyId.EnterGiveawayYoutube]: 'Subscribe',
};
export const useGiveawayEntries = () => {
    var _a;
    const { giveaway } = useGiveawaySection();
    const userAuthentication = useUserAuthentication();
    const creatorData = useCreatorData();
    // refetch giveaways
    const entryLabelParams = {
        displayName: (creatorData === null || creatorData === void 0 ? void 0 : creatorData.displayName) || 'Creator',
    };
    const entries = ((_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.actions) === null || _a === void 0 ? void 0 : _a.map((action) => {
        var _a, _b;
        const strategy = action.strategyId;
        const label = ((_a = entryLabel[strategy]) === null || _a === void 0 ? void 0 : _a.call(entryLabel, entryLabelParams)) || '?';
        const actionName = entryAction[strategy];
        return {
            id: action.id,
            label,
            score: action.score,
            isDone: Boolean((_b = action.entries) === null || _b === void 0 ? void 0 : _b.length),
            icon: entryIcon[strategy] || null,
            strategyId: action.strategyId,
            actionName,
        };
    })) || [];
    const entriesList = entries.filter((entry) => {
        return !hiddenEntryStrategyIds.includes(entry.strategyId);
    });
    return {
        entries,
        entriesList,
        isLogged: Boolean(userAuthentication),
        isEntered: Boolean(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isEntered),
    };
};
