import React, { memo } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import CheckCircle from 'root/src/client/public/ic-check-circle'
import { orNull, ternary } from 'root/src/shared/util/ramdaPlus'
import { ltGtMediaQuery } from 'root/src/client/web/commonStyles'

const style = {
	task: {
		color: '#45D7B6',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		width: 'fit-content',
		'@media (max-width: 460px)': {
			height: 30,
		},
	},
	checkCircle: {
		marginRight: 5,
		flex: 'none',
		width: 30,
		height: 30,
	},
	completed: {
		color: '#6a7784',
	},
	inComplete: {
		[ltGtMediaQuery]: {
			marginLeft: 35,
		},
	},
}

const widgetTask = memo(({ classes, title, completed }) => (
	<div className={classNames(classes.task,
		ternary(completed, classes.completed, classes.inComplete))}
	>
		{orNull(completed,
			<CheckCircle
				className={classes.checkCircle}
				alt="circle"
			/>)}
		{title}
	</div>

))

export default withStyles(style, { name: 'MuiwidgetTask' })(widgetTask)
