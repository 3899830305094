import { useAppDispatch } from 'root/src/client/logic/helpers/useStore';
import displayModal from 'root/src/client/logic/modal/actions/displayModal';
import { SIGN_IN_MODAL_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import { useCallback } from 'react';
export const useAuthModals = () => {
    const dispatch = useAppDispatch();
    const displaySignInModal = useCallback(() => {
        dispatch(displayModal(SIGN_IN_MODAL_MODULE_ID));
    }, [dispatch]);
    return {
        displaySignInModal,
    };
};
