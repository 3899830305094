import { memo, Fragment } from 'react'
import isEmpty from 'ramda/src/isEmpty'
import classNames from 'classnames'
import creatorDataConnector from 'root/src/client/logic/creatorData/connectors/creatorDataConnector'
import HelperWidget from 'root/src/client/web/record/HelperWidget'
import BackgroundImage from 'root/src/client/web/record/CreatorData/BackgroundImage'
import { GiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/GiveawaySection'
import { RewardWinner } from 'root/src/client/web/giveaway/features/RewardWinner/RewardWinner'
import { GiveawayStats } from 'root/src/client/web/giveaway/features/GiveawayStats/GiveawayStats'
import { GiveawayRewards } from 'root/src/client/web/giveaway/features/GiveawayRewards/GiveawayRewards'
import { CreatorDataInfo } from 'root/src/client/web/record/CreatorData/CreatorDataInfo'
import styles from './styles'

const CreatorData = memo(({
	mnemonicId, displayModal, isOwnProfile, introProjectCreated,
	description, socialLinks, backgroundImage,
	hasPayoutEmail, helperWidgetOnSuccess, createIntroProject,
	obsExtensionAdded, hasExtensions,
	widgetType, hasCompletedProject, hasCompletedDropProject, userRole,
	classes, gamePlatforms, isBetaTester, deleteProfileBackground,
}) => (
	<div className={classNames(classes.container)}>
		<Fragment>
			<HelperWidget
				widgetType={widgetType}
				profile={!!description || !isEmpty(socialLinks) || !!backgroundImage}
				payoutEmail={hasPayoutEmail}
				twitchUsername={mnemonicId}
				extensions={hasExtensions}
				helperWidgetOnSuccess={helperWidgetOnSuccess}
				obsExtensionAdded={obsExtensionAdded}
				hasCompletedProject={hasCompletedProject}
				hasCompletedDropProject={hasCompletedDropProject}
				createIntroProject={createIntroProject}
				introProjectCreated={introProjectCreated}
				userRole={userRole}
				gamePlatforms={gamePlatforms && !isEmpty(gamePlatforms)}
				displayModal={displayModal}
			/>
			<BackgroundImage
				{...{
					backgroundImage,
					classes,
					displayModal,
					isOwnProfile,
					deleteProfileBackground,
				}}
			/>
			<div className="flex flex-col gap-4 w-full max-w-large xl:px-12 justify-center self-center">
				<div className="flex flex-col md:flex-row gap-4 px-4 xl:px-0">
					<div className="flex">
						<CreatorDataInfo />
					</div>
					{isBetaTester && (
						<div className="flex flex-initial w-full md:w-2/5">
							<GiveawaySection />
						</div>
					)}
				</div>
				{isBetaTester && (
					<>
						<div className="flex flex-col sm:flex-row gap-4 px-4 xl:px-0 sm:items-start items-stretch w-full">
							<RewardWinner />
							<GiveawayStats />
						</div>
						<div className="mx-2">
							<GiveawayRewards />
						</div>
					</>
				)}
			</div>
		</Fragment>
	</div>
))

export default creatorDataConnector(CreatorData, styles, 'MuiCreatorData')
