import { useEffect, useState } from 'react';
export function useIsActiveTab() {
    const [isActiveTab, setIsActiveTab] = useState(!document.hidden);
    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsActiveTab(!document.hidden);
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return isActiveTab;
}
