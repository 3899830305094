import IcFacebook from 'root/src/client/public/icons/new-social-icons/ic-facebook.svg';
import IcInstagram from 'root/src/client/public/icons/new-social-icons/ic-instagram.svg';
import IcTwitter from 'root/src/client/public/icons/new-social-icons/ic-twitter.svg';
import IcDiscord from 'root/src/client/public/icons/new-social-icons/ic-discord.svg';
import IcVk from 'root/src/client/public/icons/new-social-icons/ic-vk.svg';
import IcTikTok from 'root/src/client/public/icons/ic-tiktok.svg';
import IcTwitch from 'root/src/client/public/icons/new-social-icons/ic-twitch.svg';
import IcYouTube from 'root/src/client/public/icons/new-social-icons/ic-youtube.svg';
import { creatorDataSelector } from 'root/src/client/logic/api/hooks/useCreatorData';
import { creatorProfileSelector } from 'root/src/client/logic/api/hooks/useCreatorProfile';
const isURL = (data) => {
    return typeof data === 'string' && (/^http(s)?:\/\//gi).test(data);
};
export default function socialLinksSelector(state) {
    var _a, _b, _c, _d, _e, _f;
    const creatorData = creatorDataSelector(state);
    const profile = creatorProfileSelector(state);
    const socialLinks = [
        {
            name: 'twitch',
            link: (_a = creatorData === null || creatorData === void 0 ? void 0 : creatorData.twitchData) === null || _a === void 0 ? void 0 : _a.url,
            icon: IcTwitch,
            displayName: (_b = creatorData === null || creatorData === void 0 ? void 0 : creatorData.twitchData) === null || _b === void 0 ? void 0 : _b.displayName,
        },
        {
            name: 'tiktok',
            link: (_c = creatorData === null || creatorData === void 0 ? void 0 : creatorData.tiktokData) === null || _c === void 0 ? void 0 : _c.url,
            icon: IcTikTok,
            displayName: (_d = creatorData === null || creatorData === void 0 ? void 0 : creatorData.tiktokData) === null || _d === void 0 ? void 0 : _d.displayName,
        },
        {
            name: 'youtube',
            link: (_e = creatorData === null || creatorData === void 0 ? void 0 : creatorData.youtubeData) === null || _e === void 0 ? void 0 : _e.url,
            icon: IcYouTube,
            displayName: (_f = creatorData === null || creatorData === void 0 ? void 0 : creatorData.youtubeData) === null || _f === void 0 ? void 0 : _f.displayName,
        },
        {
            name: 'facebook',
            link: profile === null || profile === void 0 ? void 0 : profile.facebook,
            icon: IcFacebook,
        },
        {
            name: 'instagram',
            link: profile === null || profile === void 0 ? void 0 : profile.instagram,
            icon: IcInstagram,
        },
        {
            name: 'twitter',
            link: profile === null || profile === void 0 ? void 0 : profile.twitter,
            icon: IcTwitter,
        },
        {
            name: 'discord',
            link: profile === null || profile === void 0 ? void 0 : profile.discord,
            username: profile === null || profile === void 0 ? void 0 : profile.discordServerName,
            icon: IcDiscord,
        },
        {
            name: 'vkontakte',
            link: profile === null || profile === void 0 ? void 0 : profile.vkontakte,
            icon: IcVk,
        },
        {
            name: 'tiktok',
            link: profile === null || profile === void 0 ? void 0 : profile.tiktok,
            icon: IcTikTok,
        },
        {
            name: 'other',
            link: profile === null || profile === void 0 ? void 0 : profile.other,
        },
    ];
    return socialLinks.reduce((acc, social) => {
        const isEmptyLink = social.link === undefined || social.link === '';
        const isDuplicate = acc.some(item => item.name === social.name);
        if (isDuplicate || isEmptyLink) {
            return acc;
        }
        if (!isURL(social.link)) {
            social.link = `https://${social.link}`;
        }
        if (!('username' in social)) {
            const separator = social.link.includes('@') ? '@' : '/';
            const username = social.link.split(separator).at(-1);
            if (!username) {
                return acc;
            }
            const item = Object.assign(Object.assign({}, social), { username, link: social.link });
            return acc.concat(item);
        }
        if (!social.username) {
            return acc;
        }
        return acc.concat(Object.assign(Object.assign({}, social), { link: social.link, username: social.username }));
    }, []);
}
