import Image from 'next/image';
import ButtonNew from 'root/src/client/web/base/ButtonNew';
import { GlowLine } from 'root/src/client/v2/common/components/GlowLine';
import { Cup, CupSize, CupVariant, } from 'root/src/client/web/giveaway/components/Cup';
import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import moment from 'moment-mini';
import { useRewardWinnerDetailsSection } from 'root/src/client/web/giveaway/features/RewardWinner/hooks/useRewardWinner';
import { BaseTooltip, BaseTooltipContent } from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip';
import { useCreatorGiveaways } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
export const RewardWinner = () => {
    return (<div className="flex flex-col bg-[rgb(28,28,28,.70)] p-5 gap-8 border">
			<RewardWinnerHeader />
			<div className="flex bg-black">
				<RewardWinnerDetails />
			</div>
		</div>);
};
const RecentWinnerGlowLines = () => {
    return (<>
			<GlowLine className="w-full" variant="purple"/>
			<GlowLine className="w-4/5" variant="green"/>
			<GlowLine className="w-3/5" variant="red"/>
		</>);
};
const RecentWinnerInfo = () => {
    var _a;
    const { winnerName, giveaway } = useRewardWinnerDetailsSection();
    return (<div className="flex flex-col justify-center items-center gap-2 p-6">
			<div className="flex gap-4 justify-center items-center">
				<Cup size={CupSize.SMALL} variant={CupVariant.Gold}/>
				<span className="flex text-light-4 text-base">Winner</span>
				<Cup size={CupSize.SMALL} variant={CupVariant.Gold}/>
			</div>
			<span className="font-mono font-bold text-lg">{winnerName !== null && winnerName !== void 0 ? winnerName : '---'}</span>
			<div className="flex gap-2 text-sm">
				<span className="text-light-4">When:</span>
				<span title={new Date((_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.endDate) !== null && _a !== void 0 ? _a : Date.now()).toLocaleTimeString()}>{moment(giveaway === null || giveaway === void 0 ? void 0 : giveaway.endDate).format('MM/DD/YYYY')}</span>
			</div>
		</div>);
};
const NoGiveawayCreated = () => {
    return (<div className="flex flex-col justify-center items-center text-sm py-6 gap-1">
			<Cup size={CupSize.SMALL} variant={CupVariant.Silver}/>
			<span className="text-center text-pink">No Giveaways Created</span>
		</div>);
};
const GiveawayInProgress = () => {
    return (<div className="flex flex-col justify-center items-center text-sm py-6 gap-1">
			<Cup size={CupSize.SMALL} variant={CupVariant.Silver}/>
			<span className="text-center text-light-4">Giveaway in progress</span>
			<span className="text-center m-2">Please check back to see who the winner is.</span>
		</div>);
};
const AwardClaimed = () => {
    var _a, _b, _c, _d, _e, _f;
    const { awardClaimed } = useRewardWinnerDetailsSection();
    if (!awardClaimed) {
        return null;
    }
    return (<div className="flex flex-col gap-2 pb-4 pt-2 items-center sm:items-start">
			<span className="font-mono text-xl font-bold">{(_b = (_a = awardClaimed.reward) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Unknown'}</span>
			<div className="flex-col items-center lg:flex-row flex justify-center gap-4">
				<div className="rounded border-2 border-solid border-teal">
					<Image width={100} height={100} src={(_d = (_c = awardClaimed.reward) === null || _c === void 0 ? void 0 : _c.imageUrl) !== null && _d !== void 0 ? _d : ''} alt="Game image"/>
				</div>
				<div className="flex flex-col items-start py-2 gap-2 text-left">
					<span className="text-light-4">Reward Claimed:</span>
					<span>{(_f = (_e = awardClaimed.reward) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : 'Unknown'} key</span>
				</div>
			</div>
		</div>);
};
const NoAwardClaimed = () => {
    return (<div className="flex flex-col items-start gap-2 text-sm">
			<span className="text-light-4">Reward Claimed:</span>
			<span className="text-base">No award claimed yet</span>
		</div>);
};
const Spacer = () => {
    return <div className="w-3/4 h-0.5 sm:h-3/4 sm:w-0.5 rounded bg-light-4"/>;
};
const ClaimReward = () => {
    const { openRedeemVouchersModal, hasRewards } = useGiveawaySection();
    return (<div className="flex flex-col items-start py-2 gap-2 text-sm text-left">
			<span className="text-light-4">Reward Claimed:</span>
			<BaseTooltip tooltip={!hasRewards && <BaseTooltipContent>Currently there are no rewards to claim</BaseTooltipContent>}>
				<ButtonNew disabled={!hasRewards} onClick={openRedeemVouchersModal} className="px-10" htmlType="button">
					<span className="uppercase">
						Claim reward
					</span>
				</ButtonNew>
			</BaseTooltip>
		</div>);
};
const RewardWinnerDetailsSection = () => {
    const { awardClaimed, userVouchers } = useRewardWinnerDetailsSection();
    if (userVouchers === null || userVouchers === void 0 ? void 0 : userVouchers.length) {
        return <ClaimReward />;
    }
    if (!awardClaimed) {
        return <NoAwardClaimed />;
    }
    return <AwardClaimed />;
};
const RewardWinnerDetails = () => {
    const { giveaway } = useRewardWinnerDetailsSection();
    const creatorGiveaways = useCreatorGiveaways();
    const isLoading = typeof creatorGiveaways === 'undefined';
    if (isLoading) {
        return <LoadingCircles width={50} height={50}/>;
    }
    if (!giveaway && !(creatorGiveaways === null || creatorGiveaways === void 0 ? void 0 : creatorGiveaways.length)) {
        return <NoGiveawayCreated />;
    }
    if (!giveaway) {
        return <GiveawayInProgress />;
    }
    return (<div className="bg-black flex flex-col sm:flex-row items-center text-sm rounded-sm px-4 py-1 gap-2">
			<RecentWinnerInfo />
			<Spacer />
			<div className="flex flex-col items-start px-8 gap-2 flex-grow">
				<RewardWinnerDetailsSection />
			</div>
		</div>);
};
const RewardWinnerHeader = () => {
    return (<div className="flex justify-between gap-8 items-center">
			<div className="flex flex-col items-end gap-1.5">
				<RecentWinnerGlowLines />
			</div>
			<h1 className="font-bold uppercase text-center">Reward Winner</h1>
			<div className="flex flex-col gap-1.5">
				<RecentWinnerGlowLines />
			</div>
		</div>);
};
