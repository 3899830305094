import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId';
export const creatorProfileSelector = (state) => {
    var _a, _b, _c;
    const recordId = currentRouteParamsRecordId(state);
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c[recordId];
};
export const useCreatorProfile = () => {
    return useAppSelector(creatorProfileSelector);
};
