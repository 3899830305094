import { UPDATE_PROFILE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { COMPLETE_WIDGET_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import updateProfile from 'root/src/client/logic/profile/actions/updateProfile'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import profileSelector from 'root/src/client/logic/app/selectors/profileSelector'
import setShareUrl from 'root/src/client/logic/static/actions/setShareUrl'
import linkHrefSelector from 'root/src/client/logic/app/selectors/linkHrefSelector'
import outputs from 'root/cfOutput'

const { domainName } = outputs

const routeMap = {
	[VIEW_CREATOR_DATA_ROUTE_ID]: {
		routeId: VIEW_CREATOR_DATA_ROUTE_ID,
		query: { modal: COMPLETE_WIDGET_SUCCESS_MODULE_ID },
	},
	[VIEW_YT_CREATOR_DATA_ROUTE_ID]: {
		routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
		query: { modal: COMPLETE_WIDGET_SUCCESS_MODULE_ID },
	},
	[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: {
		routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
		query: { modal: COMPLETE_WIDGET_SUCCESS_MODULE_ID },
	},
	[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: {
		routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
		query: { modal: COMPLETE_WIDGET_SUCCESS_MODULE_ID },
	},
}

export default () => async (dispatch, getState) => {
	const payload = { completedWidget: true }
	const state = getState()
	const { body } = await dispatch(apiRequest(UPDATE_PROFILE, payload))
	dispatch({ ...profileSelector(state), ...updateProfile(body) })
	const routeId = currentRouteId(state) || VIEW_CREATOR_DATA_ROUTE_ID
	const { routeId: nextRouteId, query } = routeMap[routeId]
	const shareUrl = linkHrefSelector(state, {
		routeId,
		routeParams: {
			recordId: currentRouteParamsRecordId(state),
		},
	})
	dispatch(setShareUrl(`https://${domainName}${shareUrl}`))
	return pushRouteClient({
		routeId: nextRouteId, routeParams: { recordId: currentRouteParamsRecordId(state) }, query,
	})
}
