import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { viewCreatorData } = appStoreLenses

export default state => pipe(
	viewCreatorData,
	prop('creatorGamePlatforms'),
)(state)
