import { makeStyles } from '@material-ui/styles';
import { colorsNew, } from 'root/src/client/web/commonStyles';
export const giveawaySectionStyles = makeStyles({
    root: {
        border: '1px solid #FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        gap: 15,
        backgroundColor: 'rgba(28, 28, 28, 0.70)',
        height: '100%',
        paddingInline: 20,
        width: '100%',
        paddingBlock: 20,
    },
    header: {
        color: colorsNew.teal,
        fontSize: 28,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontFamily: 'Roboto Mono',
    },
    noActiveGiveaway: {
        color: colorsNew.light4,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 20,
    },
    button: {
        '& button': {
            minHeight: 'auto',
        },
    },
    buttonText: {
        textTransform: 'uppercase',
        letterSpacing: 0.5,
        fontSize: 14,
    },
    createGiveawaySection: {
        paddingTop: 10,
    },
    timeRemainingLabel: {
        color: colorsNew.light4,
        textTransform: 'uppercase',
        fontSize: 12,
    },
    timeRemaining: {
        color: colorsNew.teal,
        fontSize: 28,
        fontWeight: 700,
        fontFamily: 'Roboto Mono',
    },
    activeGiveawayInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
    },
    activeGiveawayEntries: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    completedGiveawaySection: {
        display: 'flex',
        gap: 40,
    },
    rewardGiveawayComplete: {
        fontFamily: 'Roboto Mono',
        textTransform: 'uppercase',
        color: colorsNew.teal,
        fontWeight: 700,
        letterSpacing: 1,
    },
    entries: {
        fontWeight: 700,
        fontSize: 14,
    },
    steamSignInText: {
        color: colorsNew.light4,
        fontSize: 12,
    },
    creatorNoActiveGiveaway: {
        fontFamily: 'Roboto Mono',
        fontSize: 16,
        letterSpacing: 0.4,
        textAlign: 'center',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    steamSignInButton: {
        border: 'none',
        background: 'none',
        paddingBlock: 4,
        paddingInline: 4,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    flex: {
        display: 'flex',
    },
    flexColumn: {
        flexDirection: 'column',
    },
    warningText: {
        fontFamily: 'Roboto',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: 0.4,
        color: colorsNew.pink,
    },
    steamSignInRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
