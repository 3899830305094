import { useState } from 'react';
export const useGiveawayTermsCheckbox = () => {
    const [isGiveawayTermsCheckboxSelected, setIsGiveawayTermsCheckboxSelected] = useState(false);
    const toggleGiveawayTermsCheckbox = () => {
        setIsGiveawayTermsCheckboxSelected(!isGiveawayTermsCheckboxSelected);
    };
    return {
        isGiveawayTermsCheckboxSelected,
        toggleGiveawayTermsCheckbox,
    };
};
