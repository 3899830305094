import ButtonNew from 'root/src/client/web/base/ButtonNew';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { useEnterGiveawayButton } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useEnterGiveawayButton';
import { useIsAuthenticated } from 'root/src/client/v2/common/hooks/useUser';
import { BaseTooltip, BaseTooltipContent } from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip';
import { useCreatorActiveGiveaway } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import { useBetween } from 'use-between';
import { useGiveawayTermsCheckbox, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawayTermsCheckbox';
import { useGiveawaySection, } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
const EnterGiveawayButtonTooltip = () => {
    const isAuthenticated = useIsAuthenticated();
    const giveaway = useCreatorActiveGiveaway();
    if (!isAuthenticated) {
        return <BaseTooltipContent>To enter the giveaway, please log in.</BaseTooltipContent>;
    }
    if (!(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isSteamEntered)) {
        return <BaseTooltipContent>To enter the giveaway, please connect your Steam account.</BaseTooltipContent>;
    }
    return null;
};
export const EnterGiveawayButton = () => {
    const { onEnterGiveaway, isLoading, classes, isSteamEntered } = useEnterGiveawayButton();
    const { isGiveawayTermsCheckboxSelected } = useBetween(useGiveawayTermsCheckbox);
    const { isGiveawayTermsVisible } = useGiveawaySection();
    return (<BaseTooltip tooltip={<EnterGiveawayButtonTooltip />}>
			<ButtonNew onClick={onEnterGiveaway} disabled={!isSteamEntered || (isGiveawayTermsVisible && !isGiveawayTermsCheckboxSelected)} className={classes.button}>
				{isLoading ? <LoadingCircles width={20} height={20}/> : (<span className={classes.buttonText}>Enter giveaway</span>)}
			</ButtonNew>
		</BaseTooltip>);
};
