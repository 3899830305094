import getTokens from 'root/src/client/logic/api/selectors/tokensSelector'
import { CREATE_PROJECT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import updateProfile from 'root/src/client/logic/profile/actions/updateProfile'
import profileSelector from 'root/src/client/logic/creatorData/selectors/profileSelector'
import { CREATE_INTRO_DARE_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import myProfileSelector from 'root/src/client/logic/route/selectors/myProfileSelector'

export default () => async (dispatch, getState) => {
	const state = getState()
	const tokenData = getTokens(state)[0]

	const prepareAssingee = ({ image, platform, platformId, displayName }) => ({
		id: platformId,
		value: platformId,
		label: displayName,
		image,
		platform,
	})
	const payload = {
		title: 'Intro Dare Drop to your stream',
		description: 'Explain Dare Drop to your viewers',
		assignees: [
			prepareAssingee(tokenData),
		],
		games: [{
			id: 509658,
			image: 'https://static-cdn.jtvnw.net/ttv-boxart/Just%20Chatting-52x72.jpg',
			label: 'Just Chatting',
			value: 509658,
		}],
		amountRequested: 0,
		isIntroDare: true,
	}

	const profileData = profileSelector(state)
	dispatch(updateProfile({ ...profileData, introProjectCreated: true }))

	await dispatch(apiRequest(CREATE_PROJECT, payload))

	return pushRouteClient({
		...myProfileSelector(state),
		query: { modal: CREATE_INTRO_DARE_SUCCESS_MODULE_ID },
	})
}
