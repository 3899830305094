import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import socialLinksSelector from 'root/src/client/logic/creatorData/selectors/socialLinksSelector';
import { useCreatorData } from 'root/src/client/logic/api/hooks/useCreatorData';
import { useCreatorProfile } from 'root/src/client/logic/api/hooks/useCreatorProfile';
import isOwnProfileSelector from 'root/src/client/logic/creatorData/selectors/isOwnProfileSelector';
export const useCreatorDataInfo = () => {
    const socialLinks = useAppSelector(socialLinksSelector);
    const creatorData = useCreatorData();
    const profile = useCreatorProfile();
    const isOwnProfile = useAppSelector(isOwnProfileSelector);
    return {
        creatorData,
        profile,
        isOwnProfile,
        socialLinks,
    };
};
