import propEq from 'ramda/src/propEq'
import find from 'ramda/src/find'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import creatorDataHof from 'root/src/client/logic/creatorData/util/creatorDataHof'

export default (state) => {
	const mnemonicId = creatorDataHof('mnemonicId', state)
	const tokens = tokensSelector(state)
	return !!find(propEq('mnemonicId', mnemonicId), tokens)
}
