import { useCreatorRewards } from 'root/src/client/web/giveaway/hooks/useCreatorGiveaways';
import { useWindowSize } from 'root/src/client/v2/common/hooks/useWindowSize';
import { makeStyles } from '@material-ui/styles';
import { getSliderBreakpoints, } from 'root/src/client/web/giveaway/helpers/giveawayRewardsSliderBreakpoints';
// it's used because we have to override some styles from Slider
const useSliderStyles = makeStyles({
    sliderItems: {
        alignItems: 'initial !important',
        gap: 8,
    },
});
export const useGiveawayRewards = () => {
    const rewards = useCreatorRewards();
    const classes = useSliderStyles();
    const [width] = useWindowSize();
    const sliderBreakpoints = getSliderBreakpoints(width || 0);
    return {
        rewards,
        sliderBreakpoints,
        classes,
    };
};
