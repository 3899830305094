import hasExtensions from 'root/src/client/util/hasExtensions'
import userProfileHof from 'root/src/client/logic/app/util/userProfileHof'

export default (state) => {
	const extensions = userProfileHof('extensions', state)
	if (extensions) {
		return hasExtensions(extensions)
	}
	return undefined
}
