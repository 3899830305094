var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import Image from 'next/image';
import successIcon from 'root/src/client/web/giveaway/assets/ic-success.svg';
export const Root = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('flex bg-brown p-2 items-center justify-between gap-4', className)} {...props}>
			{children}
		</div>);
};
export const Label = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('p-2 gap-1 flex justify-start items-center', className)} {...props}>
			{children}
		</div>);
};
export const LabelText = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames('text-sm', className)} {...props}>
			{children}
		</span>);
};
export const Trigger = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('flex relative justify-end items-center bg-brown p-2 gap-1 justify-end', className)} {...props}>
			{children}
		</div>);
};
export const LabelIcon = (props) => {
    return (<Image {...props} width={42} height={42}/>);
};
export const ButtonContent = ({ children }) => {
    return (<div className="flex items-start">{children}</div>);
};
export const ButtonIcon = (props) => {
    return (<Image {...props} width={24} height={24}/>);
};
export const ButtonAction = ({ children }) => {
    return <span className="text-black pl-2">{children}</span>;
};
export const SuccessIcon = () => {
    return <Image src={successIcon.src} width={32} height={32} alt="Success"/>;
};
export const Score = ({ children }) => {
    return <div className="text-lg pl-3">{children}</div>;
};
