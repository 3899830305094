import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId';
export const creatorDataSelector = (state) => {
    var _a, _b, _c;
    const recordId = currentRouteParamsRecordId(state);
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b.creatorData) === null || _c === void 0 ? void 0 : _c[recordId];
};
export const useCreatorData = () => {
    return useAppSelector(creatorDataSelector);
};
export const betaTesterSelector = (state) => {
    var _a, _b, _c, _d;
    const recordId = currentRouteParamsRecordId(state);
    return (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b.creatorData) === null || _c === void 0 ? void 0 : _c[recordId]) === null || _d === void 0 ? void 0 : _d.isBetaTester;
};
export const useIsBetaTester = () => {
    return useAppSelector(betaTesterSelector);
};
