import { useGiveawaySection } from 'root/src/client/web/giveaway/features/GiveawaySection/hooks/useGiveawaySection';
import { GiveawayTimer } from 'root/src/client/web/giveaway/features/GiveawaySection/GiveawayTimer';
import { GiveawayStatus } from '@daredrop-com/rewards-api-contract';
import { Cup, CupSize, CupVariant, } from 'root/src/client/web/giveaway/components/Cup';
import Link from 'next/link';
import { giveawaysRoutes } from 'root/src/shared/giveaways/giveaways.routes';
import { BaseTooltip, BaseTooltipContent } from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { memo } from 'react';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
export const CreatorGiveawaySection = () => {
    const { classes } = useGiveawaySection();
    return (<div className={classes.root}>
			<span className={classes.header}>My giveaway</span>
			<GiveawaySection />
		</div>);
};
const CreateGiveawaySection = () => {
    const { classes, onCreateGiveaway, hasRewards } = useGiveawaySection();
    return (<div className={classes.createGiveawaySection}>
			<BaseTooltip tooltip={!hasRewards && <BaseTooltipContent>You do not have enough rewards to run a giveaway</BaseTooltipContent>}>
				<ButtonNew disabled={!hasRewards} onClick={onCreateGiveaway} className="font-bold uppercase">
					+ Create giveaway
				</ButtonNew>
			</BaseTooltip>
		</div>);
};
const GiveawayScheduleButton = memo(() => {
    const { giveawayScheduler, onCreateGiveaway } = useGiveawaySection();
    return (<ButtonNew onClick={onCreateGiveaway} className="font-bold uppercase">
			{giveawayScheduler ? '+ Edit schedule' : ' + Create schedule'}
		</ButtonNew>);
});
const DetailedStatsSection = () => {
    const { isActive } = useGiveawaySection();
    return (<Link href={giveawaysRoutes.giveawayHistoryRouteSelector()}>
			<ButtonNew className="font-bold uppercase">
				{isActive ? 'Detailed stats' : 'History'}
			</ButtonNew>
		</Link>);
};
const NoActiveGiveaway = () => {
    const { classes } = useGiveawaySection();
    return <span className={classes.noActiveGiveaway}>No active giveaway</span>;
};
const GiveawayInactive = () => {
    return (<>
			<Cup size={CupSize.MEDIUM} variant={CupVariant.Silver}/>
			<NoActiveGiveaway />
			<CreateGiveawaySection />
			<DetailedStatsSection />
		</>);
};
const GiveawayEntries = () => {
    var _a, _b;
    const { classes, giveaway } = useGiveawaySection();
    return (<div className={classes.activeGiveawayEntries}>
			<span className={classes.timeRemainingLabel}>Total entries:</span>
			<span className={classes.entries}>{(_b = (_a = giveaway === null || giveaway === void 0 ? void 0 : giveaway.stats) === null || _a === void 0 ? void 0 : _a.entryCount) !== null && _b !== void 0 ? _b : 0}</span>
		</div>);
};
const GiveawayActive = () => {
    const { isActive } = useGiveawaySection();
    return (<>
			{isActive && <GiveawayTimer />}
			<Cup size={CupSize.MEDIUM} variant={CupVariant.Silver}/>
			<GiveawayEntries />
			<GiveawayScheduleButton />
			<DetailedStatsSection />
		</>);
};
const GiveawaySchedulerActive = () => {
    return (<div className="flex flex-col items-center justify-center gap-2">
			<span className="font-bold font-mono uppercase text-xl text-center">We are preparing your giveaway</span>
			<LoadingCircles width={60} height={60}/>
		</div>);
};
const GiveawaySection = () => {
    const { giveaway, giveawayScheduler } = useGiveawaySection();
    if (!giveaway) {
        if ((giveawayScheduler === null || giveawayScheduler === void 0 ? void 0 : giveawayScheduler.status) === GiveawayStatus.Active) {
            return <GiveawaySchedulerActive />;
        }
        return <GiveawayInactive />;
    }
    if (giveaway.status === GiveawayStatus.Active) {
        return <GiveawayActive />;
    }
    return null;
};
